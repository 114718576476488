export const hardwareTypes = [
    "rpibridge",
    "espbridge",
    "radio",
    "roofline-receiver",
    "landscape-receiver",
    "landscape-light",
    "lpl-receiver",
    "lpl-driver"
] as const;

export type HardwareType = typeof hardwareTypes[number];

export function tryParseHardwareType(src: unknown) {
    try {
        if (typeof src !== "string") return undefined;
        const lowered = src.toLowerCase();
        if (hardwareTypes.includes(lowered as HardwareType)) return lowered as HardwareType;
        return undefined;
    } catch {
        return undefined;
    }
}

