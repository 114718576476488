import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";
import { hardwareTypes } from "../../types/hardware-type";
import style from "./style.css";
import { getHardwareDisplayName } from '../../utilities/display-names';

interface IHeaderProps {
    logout: () => void;
}

const Header: FunctionalComponent<IHeaderProps> = (props: IHeaderProps) => {
    return (
        <header class={style.header}>
            <h1>Update Server</h1>
            <nav>
                <Link activeClassName={style.active} href="/">
                    Dashboard
                </Link>
                {
                    hardwareTypes.map((hardwareType) => (
                        <Link activeClassName={style.active} href={`/${hardwareType}`}>
                            {getHardwareDisplayName(hardwareType)}
                        </Link>
                    ))
                }
                <Link activeClassName={style.active} href="/groups">
                    Groups
                </Link>
                <Link activeClassName={style.active} href="/devices">
                    Devices
                </Link>
                <button class="text-white mr-4 ml-8" onClick={props.logout}>
                    logout
                </button>
            </nav>
        </header>
    );
};

export default Header;
