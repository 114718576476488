import { HardwareType } from "../types/hardware-type";

type HardwareDisplayNameMap = Record<HardwareType, string>;

const hardwareDisplayNames: HardwareDisplayNameMap = {
    "rpibridge": "Pi Bridge",
    "espbridge": "ESP Bridge",
    "radio": "Radio",
    "roofline-receiver": "Roofline Receiver",
    "landscape-receiver": "Landscape Receiver",
    "landscape-light": "Landscape Light",
    "lpl-receiver": "LPL Receiver",
    "lpl-driver": "LPL Driver",
};

export function getHardwareDisplayName(hardwareType: HardwareType) {
    return hardwareDisplayNames[hardwareType];
}
